const App = {
  SetBackground: () => {
    $("[setBackground]").each(function () {
      var background = $(this).attr("setBackground");
      $(this).css({
        "background-image": "url(" + background + ")",
        "background-size": "cover",
        "background-position": "center center",
      });
    });
    $("[setBackgroundRepeat]").each(function () {
      var background = $(this).attr("setBackgroundRepeat");
      $(this).css({
        "background-image": "url(" + background + ")",
        "background-repeat": "repeat",
      });
    });
  },
  EqualHeightElement: (el) => {
    let height = 0;
    let thisHeight = 0;
    $(el).each(function () {
      thisHeight = $(this).height();
      if (thisHeight > height) {
        height = thisHeight;
      }
    });
    $(el).height(height);
  },
  ScrollTo: (y) => {
    $("html, body").animate(
      {
        scrollTop: y,
      },
      1000
    );
  },
  Init: () => {
    App.ScrollTo();
    App.SetBackground();
  },
};

function InitSlider() {
  var heroBanner = new Swiper(".hero-banner .swiper-container", {
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 0,
    // autoplay: {
    //   delay: 7000,
    // },
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  });
  var pageIndex4 = new Swiper(".page-index-4 .swiper-container", {
    speed: 1500,
    spaceBetween: 50,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
    navigation: {
      clickable: !0,
      nextEl: ".page-index-4 .swiper-next",
      prevEl: ".page-index-4 .swiper-prev",
    },
    on: {
      resize: function () {
        pageIndex4.update();
      },
    },
  });

  var productSlider = new Swiper(".product-slider .swiper-container", {
    spaceBetween: 30,
    slidesPerView: 2.2,
    autoplay: {
      delay: 5000,
    },
    loop: true,
    loopedSlides: 5,
    breakpoints: {
      768: {
        spaceBetween: 15,
        slidesPerView: 1.5,
      },
      500: {
        spaceBetween: 15,
        slidesPerView: 1.2,
      },
    },
    navigation: {
      clickable: !0,
      nextEl: ".product-slider .swiper-next",
      prevEl: ".product-slider .swiper-prev",
    },
    on: {
      resize: function () {
        productSlider.update();
      },
    },
  });
  if ($(".reviews-section").length) {
    var numSlick = 0;
    $(".reviews-section .slick-container").each(function () {
      numSlick++;
      $(this)
        .addClass("slider-" + numSlick)
        .slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
          prevArrow: $(this).parent(".tab-content").find(".swiper-prev"),
          nextArrow: $(this).parent(".tab-content").find(".swiper-next"),
        });
    });
  }
  if ($(".product-slider-2").length) {
    var numSlick = 0;
    $(".product-slider-2 .slick-container").each(function () {
      numSlick++;
      $(this)
        .addClass("slider-" + numSlick)
        .slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
          prevArrow: $(this).parent(".tab-content").find(".swiper-prev"),
          nextArrow: $(this).parent(".tab-content").find(".swiper-next"),
        });
    });
  }

  var vertagearAllianceSlider = new Swiper(
    ".vertagear-alliance .swiper-container",
    {
      spaceBetween: 50,
      slidesPerView: 6,
      loop: false,
      breakpoints: {
        1025: {
          spaceBetween: 30,
          slidesPerView: 5,
        },
        768: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
        575: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
      },
      navigation: {
        clickable: !0,
        nextEl: ".vertagear-alliance .swiper-next",
        prevEl: ".vertagear-alliance .swiper-prev",
      },
      on: {
        resize: function () {
          vertagearAllianceSlider.update();
        },
      },
    }
  );
  var whatsNewSlider = new Swiper(".whats-new .swiper-container", {
    spaceBetween: 20,
    slidesPerView: 3,
    loop: false,
    autoplay: {
      delay: 5000,
    },
    breakpoints: {
      768: {
        spaceBetween: 15,
        slidesPerView: 2,
      },
    },
    navigation: {
      clickable: !0,
      nextEl: ".whats-new .swiper-next",
      prevEl: ".whats-new .swiper-prev",
    },
    on: {
      resize: function () {
        whatsNewSlider.update();
      },
    },
  });
  var tblProductsSlider = new Swiper(
    ".product-comparison-chart .swiper-container",
    {
      spaceBetween: 0,
      slidesPerView: 4,
      breakpoints: {
        992: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        576: {
          slidesPerView: 1,
        },
      },
      navigation: {
        clickable: !0,
        nextEl: ".product-comparison-chart .swiper-next",
        prevEl: ".product-comparison-chart .swiper-prev",
      },
      on: {
        resize: function () {
          tblProductsSlider.update();
        },
      },
    }
  );
  if ($(".product-detail-slider").length) {
    var galleryThumbs = new Swiper(".thumbs", {
      direction: "vertical",
      spaceBetween: 15,
      slidesPerView: 5,
      slideToClickedSlide: true,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        768: {
          direction: "horizontal",
          slidesPerView: 5,
          spaceBetween: 15,
        },
        575: {
          slidesPerView: 4,
          direction: "horizontal",
        },
        375: {
          slidesPerView: 4,
          direction: "horizontal",
        },
      },
      navigation: {
        clickable: !0,
        nextEl: ".product-detail-slider .swiper-next",
        prevEl: ".product-detail-slider .swiper-prev",
      },
      on: {
        resize: function () {
          galleryThumbs.update();
        },
      },
    });
    var galleryTop = new Swiper(".gallery", {
      spaceBetween: 10,
      thumbs: {
        swiper: galleryThumbs,
      },
      on: {
        resize: function () {
          galleryTop.update();
        },
      },
    });
    // galleryTop.controller.control = galleryThumbs;
    // galleryThumbs.controller.control = galleryTop;
  }
}

function Headers() {
  $(document).on("click", ".toggle-btn", function () {
    $(this).toggleClass("active");
    $(".mobile-nav-inner").toggleClass("active");
    $("body").toggleClass("overflow-hidden");
  });
  $(window).on("resize", function () {
    $("header .mobile-nav-inner").removeClass("active");
    $("body").removeClass("overflow-hidden");
    $(".back-drop").removeClass("active");
    $(".toggle-btn").removeClass("active");
  });
  $(document).on("click", ".has-mega .heading", function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).parents(".item").find("ul").slideUp();
    } else {
      $(".has-mega .heading").removeClass("active");
      $(".has-mega .item ul").slideUp();
      $(this).addClass("active");
      $(this).parents(".item").find("ul").slideDown();
    }
  });
}
//mapping
function mappings() {
  var moveSearch = new MappingListener({
    selector: ".header .searchbox-wrap",
    mobileWrapper: ".header .mobile-nav-inner",
    mobileMethod: "appendTo",
    desktopWrapper: ".header .account",
    desktopMethod: "insertBefore",
    breakpoint: 1025,
  }).watch();
  var moveNav = new MappingListener({
    selector: "header .menu",
    mobileWrapper: ".mobile-nav-inner",
    mobileMethod: "appendTo",
    desktopWrapper: "header .logo",
    desktopMethod: "insertAfter",
    breakpoint: 1025,
  }).watch();
  var moveText = new MappingListener({
    selector: ".header .header-bottom-wrapper",
    mobileWrapper: ".header .mobile-nav-inner",
    mobileMethod: "appendTo",
    desktopWrapper: ".header .mega-panel",
    desktopMethod: "appendTo",
    breakpoint: 1025,
  }).watch();
}

function jarallax() {
  $(".jarallax").jarallax({
    speed: 0.2,
    disableParallax: / iPad | iPhone | iPod | Android /,
    disableVideo: / iPad | iPhone | iPod | Android /,
  });
}

function myTabs() {
  $(".my-tabs").each(function () {
    var $this = $(this);
    $(".tabs-nav li:first").addClass("active");
    $this.find(".tab-content:first").show();
    $this.find(".tab-container .tab-content:first").show();
    $this.find(".tabs-nav li").click(function () {
      var corresponding = $(this).data("tab");
      $(".tab-content").hide();
      $(".tab-content." + corresponding).show();
      $(".tabs-nav li").removeClass("active");
      $(this).addClass("active");
      $(".slick-container").slick("setPosition", 0);
    });
  });
}
function sidebarDropdown() {
  $(document).on("click", ".side-nav-toggle", function () {
    $(this).toggleClass("active");
    $(this).parents(".side-nav").find(".side-nav-inner").toggle();
  });
}
function fixedNav() {
  var nav = $(".header");
  if (nav.length) {
    $(window).on("load scroll", function () {
      if ($(window).scrollTop() > 150) {
        nav.addClass("fixed");
      } else {
        nav.removeClass("fixed");
      }
    });
  }
}
function selectUi() {
  $(".dropdown").on("click", ".dropdown-select", function () {
    if ($(this).parents(".dropdown").hasClass("open")) {
      $(this).parents(".dropdown").removeClass("open");
      $(this).siblings(".dropdown-list").hide();
    } else {
      $(".dropdown").removeClass("open");
      $(this).parents(".dropdown").addClass("open");
      $(".dropdown-list").hide();
      $(this).siblings(".dropdown-list").show();
    }
  });
  var allOptions = $(".dropdown-list").children(".dropdown-item");
  $(".dropdown-list").on("click", ".dropdown-item", function () {
    allOptions.removeClass("selected");
    $(this).addClass("selected");
    $(this)
      .parents(".dropdown")
      .find(".dropdown-selected")
      .html($(this).html())
      .css("color", "#000");
    $(this).parents(".dropdown-list").toggle();
    $(this).parents(".dropdown").toggleClass("open");
    $("input#inputInit").val($(this).data("val"));
  });
  var $menu = $(".dropdown");

  $(document).mouseup(function (e) {
    if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
      $menu.removeClass("open");
      $menu.find(".dropdown-list").hide();
    }
  });
}
function checkWidth() {
  var $window = $(window);
  var windowsize = $window.width();
  if (windowsize >= 1025) {
    $(".has-mega .item ul").removeAttr("style");
  }

  $(window).on("load resize", function () {
    $(".slider-container").each(function () {
      var boxheight = $(this).find(".box-img").outerHeight();
      var btnHeight = $(this).find(".swiper-next").outerHeight();
      $(this)
        .find(".swiper-next,.swiper-prev")
        .css("top", boxheight / 2 - btnHeight / 2);
    });
  });
}
function accordion() {
  if ($(".accordions").length) {
    $(".accordion-item.active").find(".accordion-content").show();
    $(".accordion-heading").on("click", function () {
      if (!$(this).parent().is(".active")) {
        $(this)
          .parent()
          .toggleClass("active")
          .children(".accordion-content")
          .slideToggle(500)
          .parent()
          .siblings(".active")
          .removeClass("active")
          .children(".accordion-content")
          .slideToggle(500);
      } else {
        $(this).parent().toggleClass("active");
        $(this).next().slideToggle(500);
      }
    });
  }
}

function adjustProductAmount() {
  $(".plus").on("click", function () {
    $(this)
      .parents(".amount")
      .find(".input")
      .val(+$(this).parents(".amount").find(".input").val() + 1);
    $(".woocommerce-cart-form .update-cart.btn").removeAttr("disabled");
  });
  $(".minus").click(function () {
    if ($(this).parents(".amount").find(".input").val() > 1) {
      if ($(this).parents(".amount").find(".input").val() > 1)
        $(this)
          .parents(".amount")
          .find(".input")
          .val(+$(this).parents(".amount").find(".input").val() - 1);
    }
  });
}

function languageDropdown() {
  $(".language-wrapper").on("click", ".language-active", function () {
    $(".language-list").appendTo($(this).parents(".language-wrapper"));
    $(".language-list").toggle();
  });
  $(".language-list").on("click", ".language-item input", function () {
    if ($(this).is(":checked")) {
      $(".language-active .flag").html(
        $(this).parents(".language-item").find(".flag").html()
      );
      $(".language-active span").html($(this).data("value"));
    }
    $(this).parents(".language-wrapper").find(".language-list").toggle();
  });
  var lg = $(".language-wrapper");

  $(document).mouseup(function (e) {
    if (!lg.is(e.target) && lg.has(e.target).length === 0) {
      lg.find(".language-list").hide();
    }
  });
}
function searchIcon() {
  $(".header").on("click", ".search-toggle", function () {
    $(this).toggleClass("active");
    $(".searchbox").toggle();
  });
}
function uploadPhoto() {
  $("input:file").on("change", function () {
    var target = $(this);
    var relatedTarget = target.siblings(".file-name");
    var fileName = target[0].files[0].name;
    relatedTarget.val(fileName);
  });
  $(".upload-btn").on("click", function () {
    $(this).siblings("input:file").click();
  });
}
function checkboxForm() {
  $(".shipping-info .custom-checkbox input").click(function () {
    if ($(this).is(":checked")) {
      $(this).parents(".shipping-info").find(".another-address").hide();
    } else {
      $(this).parents(".shipping-info").find(".another-address").show();
    }
  });
}
function moreLessContent() {
  var showChar = 675;
  var ellipsestext = "...";
  var moretext = "Read More";
  var lesstext = "Read Less";
  if ($(".more-less-content").length) {
    $(".more-less-content").each(function () {
      var content = $(this).html();

      if (content.length > showChar) {
        var contentExcert = content.substr(0, showChar);
        var contentRest = content.substr(showChar, content.length - showChar);
        var html =
          contentExcert +
          '<span class="toggle-text-ellipses">' +
          ellipsestext +
          ' </span> <span class="toggle-text-content"><span>' +
          contentRest +
          '</span><a href="javascript:;" class="toggle-text-link">' +
          moretext +
          "</a></span>";

        $(this).html(html);
      }
    });
  }

  //Toggle content when click on read more link
  $(".toggle-text-link").click(function () {
    if ($(this).hasClass("less")) {
      $(this).removeClass("less");
      $(this).html(moretext);
    } else {
      $(this).addClass("less");
      $(this).html(lesstext);
    }
    $(this).parent().prev().toggle();
    $(this).prev().toggle();
    return false;
  });
  //load more
  $(".load-more-content .item").slice(0, 4).show();
  $(".load-more").on("click", function (e) {
    e.preventDefault();
    var IH = $(this).parents(".load-more-content").find(".item:hidden");
    IH.slice(0, 4).slideDown();
    if ($(IH).length == 0) {
      $(this).fadeOut("slow");
    }
    $("html,body").animate(
      {
        scrollTop: $(this).offset().top,
      },
      1500
    );
  });
}
function changeColorProduct() {
  $(".product-item").each(function () {
    var $this = $(this);
    $this.find(".color-list li:first").addClass("selected");
    $this.find(".box-img img:first").show();
    $this.find(".box-img img:first").show();
    $this.find(".color-list li").click(function () {
      var corresponding = $(this).data("color");
      $(this).parents(".product-item").find(".box-img img").hide();
      $(this)
        .parents(".product-item")
        .find(".box-img img." + corresponding)
        .show();
      $(this).parents(".color-list").find("li").removeClass("selected");
      $(this).addClass("selected");
    });
  });
}
$(window).resize(checkWidth).trigger("resize");
$(document).ready(function () {
  App.Init();
  InitSlider();
  mappings();
  Headers();
  fixedNav();
  selectUi();
  jarallax();
  moreLessContent();
  searchIcon();
  uploadPhoto();
  languageDropdown();
  accordion();
  sidebarDropdown();
  myTabs();
  changeColorProduct();
  checkboxForm();
  adjustProductAmount();
  checkWidth();
  $(".toggle-pass").click(function () {
    $(this).toggleClass("active");
    var input = $(this).parents(".form-group").find("input");
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });
  $(".wear-and-tear-sub input").click(function () {
    var contentHeight = $(this)
      .parents(".check")
      .find(".content")
      .outerHeight();
    if ($(this).is(":checked")) {
      $(this).parents(".check").css({ "padding-bottom": contentHeight });
    } else {
      $(this).parents(".check").removeAttr("style");
    }
  });
  $(".moreless-button").click(function () {
    $(this).parents(".content").find(".more-text").toggle();
    if ($(this).text() == "read more") {
      $(this).text("read less");
    } else {
      $(this).text("read more");
    }
  });
});
